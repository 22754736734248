import Image from "next/image";
import { useRouter } from "next/router";
import React, { memo } from "react";
import { TbLogout } from "react-icons/tb";
import Label from "../atoms/asside/label";
import MenuBtn from "../atoms/asside/menu-btn";
import Button from "../atoms/Button";
import { useQueryClient } from "@tanstack/react-query";
import useAdminMe from "@/store/useAdminMe";
import useToken from "@/store/useToken";

const Asside = () => {
  const { asPath, pathname, push } = useRouter();
  const { adminMe } = useAdminMe();

  const { resetAdmin } = useAdminMe();
  const { setToken } = useToken();
  const queryClient = useQueryClient();

  const logout = () => {
    resetAdmin();
    queryClient.clear();
    setToken("");
    push("/login");
  };

  return (
    <div className="asside mb-10 w-3/12 overflow-auto p-6">
      <div className="flex flex-col gap-6">
        {/* avatar */}
        <div className="flex flex-col items-center gap-3">
          <div className="relative h-20 w-20">
            <Image src={"/icon/avatar.svg"} alt="avatar" fill />
          </div>
          <p className="text-lg font-bold">{adminMe?.name}</p>
        </div>
        {/* end avatar */}

        {/* menu utama */}
        <div className="flex flex-col gap-1">
          <Label label="menu utama" />
          <MenuBtn icon="/icon/home-icon.svg" label="Dashboard" active={asPath == "/"} href="/" />

          <MenuBtn
            icon="/icon/user-icon.svg"
            label="akun users"
            active={["/user", "/report/trucker", "/report/shipper", "/insentif-sticker"].includes(asPath)}
            dropdown={[
              {
                label: "detail user",
                href: "/user",
                active: asPath == "/user",
              },

              {
                label: "report iklan trucker",
                href: "/report-iklan/trucker",
                active: asPath == "/report-iklan/trucker",
              },
              {
                label: "report iklan shipper",
                href: "/report-iklan/shipper",
                active: asPath == "/report-iklan/shipper",
              },
              {
                label: "Insentif Sticker",
                href: "/insentif-sticker",
                active: asPath == "/insentif-sticker",
              },
            ]}
          />
          <MenuBtn
            icon="/icon/company-shipper.svg"
            label="Informasi Usaha"
            active={asPath == "/perusahaan"}
            href="/perusahaan"
          />
        </div>

        {/* laporan */}
        <div className="flex flex-col gap-1">
          <Label label="laporan" />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="tabel summary"
            active={asPath == "/laporan/summary"}
            href="/laporan/summary"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Status User"
            dropdown={[
              {
                label: "Belum Melengkapi Informasi Usaha",
                href: "/laporan/status-user/belum-melengkapi-informasi-usaha",
                active: pathname.match(/laporan\/status-user\/belum-melengkapi-informasi-usaha/) ? true : false,
              },
              {
                label: "Belum Berlangganan",
                href: "/laporan/status-user/belum-berlangganan",
                active: pathname.match(/laporan\/status-user\/belum-berlangganan/) ? true : false,
              },
              {
                label: "Belum Posting Iklan",
                href: "/laporan/status-user/belum-posting-iklan/shipper",
                active: pathname.match(/laporan\/status-user\/belum-posting-iklan/) ? true : false,
              },
              {
                label: "Sudah Posting Iklan",
                href: "/laporan/status-user/sudah-posting-iklan/shipper",
                active: pathname.match(/laporan\/status-user\/sudah-posting-iklan/) ? true : false,
              },
            ]}
          />
          {/* Laporan iklan */}
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Iklan"
            active={pathname.includes("/laporan/iklan")}
            dropdown={[
              {
                label: "Laporan Data Iklan",
                href: "/laporan/iklan/shipper",
                active: pathname === "/laporan/iklan/[service]",
              },
              {
                label: "Search Iklan",
                href: "/laporan/iklan/shipper/search",
                active: pathname === "/laporan/iklan/[service]/search",
              },
            ]}
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="penjualan trucker"
            active={asPath == "/laporan/penjualan/trucker"}
            href="/laporan/penjualan/trucker"
          />
          {/* <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="durasi berlangganan"
            active={asPath == "/laporan/durasi-berlangganan"}
            href="/laporan/durasi-berlangganan"
          /> */}
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Akun Blokir Akun"
            active={asPath == "/laporan/blocked"}
            href="/laporan/blocked"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="hapus akun"
            active={asPath == "/laporan/hapus-akun"}
            href="/laporan/hapus-akun"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="deal iklan shipper"
            active={asPath == "/laporan/deal/shipper"}
            href="/laporan/deal/shipper"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="deal iklan trucker"
            active={asPath == "/laporan/deal/trucker"}
            href="/laporan/deal/trucker"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Laporan Pembelian Golek Koin"
            active={asPath.includes("/laporan/koin")}
            href="/laporan/koin"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Laporan Iklan Dilihat dan Dihubungi"
            active={asPath.includes("/laporan/seen-contacted")}
            href="/laporan/seen-contacted/shipper/seen"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Laporan Analytics"
            active={asPath.includes("/laporan/analytics")}
            href="/laporan/analytics"
          />
        </div>

        {/* iklan */}
        <div className="flex flex-col gap-1">
          <Label label="iklan" />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="iklan shipper"
            active={asPath == "/iklan/shipper"}
            href="/iklan/shipper"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="iklan trucker"
            active={asPath == "/iklan/trucker"}
            href="/iklan/trucker"
          />
          <MenuBtn
            icon="/icon/ads-shipper.svg"
            label="posting iklan shipper"
            active={asPath == "/iklan/admin/shipper"}
            href="/iklan/admin/shipper"
          />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="posting iklan trucker"
            active={asPath == "/iklan/admin/trucker"}
            href="/iklan/admin/trucker"
          />
        </div>

        {/* Riwayat Berlangganan */}
        <div className="flex flex-col gap-1">
          <Label label="riwayat berlangganan" />
          <MenuBtn
            icon="/icon/log-subcribe-trucker.svg"
            label="trucker"
            href="/riwayat-berlangganan/trucker"
            active={asPath == "/riwayat-berlangganan/trucker"}
          />
        </div>

        {/* master */}
        <div className="flex flex-col gap-1">
          <Label label="master" />
          <MenuBtn
            icon="/icon/master-trucker-shipper.svg"
            label="Master Trucker & Shipper"
            active={
              asPath == "/master/trucker-shipper/metode-pelunasan" ||
              asPath == "/master/trucker-shipper/klaim-poin" ||
              asPath == "/master/trucker-shipper/klaim-poin/set-poin" ||
              asPath == "/master/trucker-shipper/jenis-paket"
            }
            dropdown={[
              {
                label: "jenis paket",
                href: "/master/trucker-shipper/jenis-paket",
                active: asPath == "/master/trucker-shipper/jenis-paket",
                hidden: adminMe?.role === "admin",
              },
              {
                label: "harga trucker",
                href: "/master/harga-subscription/trucker",
                active: asPath == "/master/harga-subscription/trucker",
                hidden: adminMe?.role === "admin",
              },

              {
                label: "metode pelunasan",
                href: "/master/trucker-shipper/metode-pelunasan",
                active: asPath == "/master/trucker-shipper/metode-pelunasan",
                hidden: adminMe?.role === "admin",
              },
              {
                label: "klaim poin",
                href: "/master/trucker-shipper/klaim-poin",
                active:
                  asPath == "/master/trucker-shipper/klaim-poin" ||
                  asPath == "/master/trucker-shipper/klaim-poin/set-poin",
              },
            ]}
          />
          {adminMe?.role === "super_admin" && (
            <MenuBtn
              icon="/icon/master-trucker.svg"
              label="master truck"
              dropdown={[
                {
                  label: "kategori",
                  href: "/master/kategori-truk",
                  active: pathname.match(/master\/kategori-truk/) ? true : false,
                },
                {
                  label: "armada",
                  href: "/master/armada-truk",
                  active: pathname.match(/master\/armada-truk/) ? true : false,
                },
              ]}
            />
          )}
          <MenuBtn
            icon="/icon/master-admin.svg"
            label="Master admin"
            href="/master/admin"
            active={pathname.match(/master\/admin/) ? true : false}
          />
          {/* <MenuBtn
            icon="/icon/master-trucker.svg"
            label="master poin & voucher"
            active={
              pathname == "/master/poin-voucher/pembayaran-e-wallet" ||
              pathname == "/master/poin-voucher/pembayaran-e-wallet/[id]"
            }
            dropdown={[
              { label: "poin user" },
              {
                label: "promo",
                active: pathname.match(/master\/poin-voucher\/promo/)
                  ? true
                  : false,
                href: "/master/poin-voucher/promo",
              },
              {
                label: "penukaran saldo poin",
                active: pathname.match(
                  /master\/poin-voucher\/penukaran-saldo-poin/
                )
                  ? true
                  : false,
                href: "/master/poin-voucher/penukaran-saldo-poin",
              },
              {
                label: "pembayaran E-Wallet",
                active:
                  pathname == "/master/poin-voucher/pembayaran-e-wallet" ||
                  pathname == "/master/poin-voucher/pembayaran-e-wallet/[id]",
                href: "/master/poin-voucher/pembayaran-e-wallet",
              },
            ]}
          /> */}
          {adminMe?.role === "super_admin" && (
            <MenuBtn
              icon="/icon/master-trucker.svg"
              label="Replacement Paket"
              active={
                pathname == "/master/replacement-paket/trucker" ||
                pathname == "/master/replacement-paket/trucker/[operation]"
              }
              dropdown={[
                {
                  label: "trucker",
                  href: "/master/replacement-paket/trucker",
                  active: asPath == "/master/replacement-paket/trucker",
                },
              ]}
            />
          )}
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="Banner Promo"
            href="/master/banner-promo/shipper"
            active={pathname == "/master/banner-promo/shipper" || pathname == "/master/banner-promo/trucker"}
          />
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="Setting PopUp Promo"
            href="/setting/popup-promo"
            active={pathname == "/setting/popup-promo"}
          />
          {adminMe?.role === "super_admin" && (
            <MenuBtn
              icon="/icon/master-trucker.svg"
              label="Master Segera Berangkat"
              active={asPath == "/master/segera-berangkat/shipper" || asPath == "/master/segera-berangkat/trucker"}
              dropdown={[
                {
                  label: "shipper",
                  href: "/master/segera-berangkat/shipper",
                  active: asPath == "/master/segera-berangkat/shipper",
                },
                {
                  label: "trucker",
                  href: "/master/segera-berangkat/trucker",
                  active: asPath == "/master/segera-berangkat/trucker",
                },
              ]}
            />
          )}
          {/* master hadiah dan resi */}
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="Master Hadiah"
            href="/master/reward"
            active={pathname == "/master/reward"}
          />
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="Resi Hadiah"
            href="/master/reward/resi"
            active={pathname == "/master/reward/resi"}
          />
        </div>

        {/* notifikasi */}
        <div className="flex flex-col gap-1">
          <Label label="Notifikasi" />
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="Pengaturan Notifikasi"
            href="/notifikasi"
            active={asPath == "/notofikasi"}
          />
        </div>

        {/* Mass Upload */}
        <div className="flex flex-col gap-1">
          <Label label="Mass Upload" />
          <MenuBtn
            icon="/icon/ads-trucker.svg"
            label="Mass Upload Iklan"
            href="/mass-upload"
            active={asPath == "/mass-upload"}
          />
        </div>

        {/* golek koin */}
        <div className="flex flex-col gap-1">
          <Label label="Golek Koin" />
          <MenuBtn
            icon="/icon/master-trucker.svg"
            label="riwayat transaksi golek koin"
            active={asPath == "/koin/riwayat"}
            href="/koin/riwayat"
          />
          {adminMe?.role == "super_admin" && (
            <>
              <MenuBtn
                icon="/icon/master-trucker.svg"
                label="setting top up"
                active={asPath == "/koin/top-up"}
                href="/koin/top-up"
              />
              <MenuBtn
                icon="/icon/master-trucker.svg"
                label="setting Hadiah"
                active={pathname == "/koin/hadiah"}
                href="/koin/hadiah?tab=koin-registrasi"
              />
              <MenuBtn
                icon="/icon/master-trucker.svg"
                label="Setting Poin Posting"
                active={pathname == "/setting/poin-posting"}
                href="/setting/poin-posting"
              />
            </>
          )}
        </div>

        {adminMe?.role == "super_admin" && (
          <>
            {/* master data target */}
            <div className="flex flex-col gap-1">
              <Label label="master data target" />
              <MenuBtn
                icon="/icon/master-data-target.svg"
                label="master data target"
                href="/master/data-target"
                active={asPath == "/master/data-target"}
              />
            </div>

            {/* setting */}
            <div className="flex flex-col gap-1">
              <Label label="setting" />
              <MenuBtn
                icon="/icon/setting.svg"
                label="midtrans"
                active={asPath == "/setting/midtrans"}
                href="/setting/midtrans"
              />
            </div>
          </>
        )}

        <Button prependIcon={<TbLogout className="text-xl" />} onClick={() => logout()} color="danger-contained">
          Logout
        </Button>
        {/* end menu */}
      </div>
    </div>
  );
};

export default memo(Asside);
