import axios from "@/plugins/axios";
import useAdminMe from "@/store/useAdminMe";
import useToken from "@/store/useToken";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef, useState } from "react";
import Nav from "../atoms/nav";
import Asside from "../organisms/asside";
import * as Sentry from "@sentry/nextjs";
import { usePreserveScroll } from "@/hooks/usePreserveScroll";
import GokilAsside from "../organisms/GolekIklan/GokilAsside";
import { usePreserveTableScroll } from "@/hooks/usePreserveTableScroll";

const Template = ({
  children,
  intercept,
}: {
  children: ReactNode;
  intercept?: boolean;
}) => {
  const router = useRouter();
  const { token, setToken } = useToken();
  const { setAdminMe, statusAdmin, setStatusAdmin } = useAdminMe();

  const omitDefaultLayout = ["/homepage"];

  const [adminFetching, setAdminFetching] = useState(true);

  const watchRef = useRef<HTMLDivElement>(null);
  usePreserveScroll(watchRef, []);
  usePreserveTableScroll([]);

  useEffect(() => {
    if (!token) {
      router.push("/login").then(() => {
        setAdminFetching(false);
      });
      return;
    }
    if (statusAdmin === "NOT FETCH") {
      setAdminFetching(true);
      return;
    } else if (statusAdmin === "NOT FOUND") {
      router.push("/login").then(() => {
        setAdminFetching(false);
      });
      return;
    }

    setAdminFetching(false);
  }, [statusAdmin, token, router.pathname]);

  // otomatic bearer token axios
  useEffect(() => {
    const interceptors = axios.interceptors.request.use((config) => {
      if (token) {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    return () => {
      axios.interceptors.request.eject(interceptors);
    };
  }, [token]);

  // handle jwt error
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error?.response?.data?.detail?.msg?.match(
            /Signature has expired.|Signature verification failed.|silahkan login/
          )
        ) {
          setToken("");
          setAdminMe(null);
          router.push("/login");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [axios]);

  // fetch admin me
  useEffect(() => {
    if (token) {
      if (statusAdmin === "NOT FETCH") {
        axios
          .post("/admin/admins/me")
          .then(function (response) {
            setAdminMe(response.data);
            setStatusAdmin("HAVE ADMIN ME");
            Sentry.setUser({
              id: response.data.id.toString(),
              email: response.data.email,
              username: response.data.name,
              segment: response.data.role,
            });
          })
          .catch((error) => {
            setStatusAdmin("NOT FOUND");
          });
      }
    }
  }, [token, setAdminMe, statusAdmin, setStatusAdmin]);

  if (intercept || omitDefaultLayout.includes(router.pathname)) {
    return <>{children}</>;
  }

  if (adminFetching)
    return (
      <div className="ADMIN LOADDDDD flex-center fixed inset-0 z-50 bg-neutral-500">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div className="relative bg-neutral-400 text-white-0">
      {/* layout */}
      <div className="relative flex h-screen">
        {/* asside */}
        {router.isReady && router.pathname.includes("/gokil") ? (
          <GokilAsside />
        ) : (
          <Asside />
        )}
        {/* end asside */}

        {/* content */}
        <div
          className="CONTENT w-9/12 overflow-auto bg-neutral-300 pb-24"
          ref={watchRef}
          key={router.pathname}
        >
          <Nav />
          <div className="px-8 py-10" key={router.pathname}>
            {children}
          </div>
        </div>
        {/* end content */}
      </div>
      {/* end layout */}

      {/* footer */}
      <div className="absolute bottom-0 z-20 w-full bg-neutral-400 text-center text-white-0">
        <p>© 2024 Admin PT. Golektruk dot com</p>
      </div>
    </div>
  );
};

export default Template;
