import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import useFilter from "store/useFilter";
import cn from "classnames";
import useScrollPositions from "@/store/useScrollPositions";

type MenuBtn_t = {
  label: string;
  href?: string;
  icon: string;
  dropdown?: {
    label: string;
    href?: string;
    active?: boolean;
    hidden?: boolean;
  }[];
  active?: boolean;
};

const MenuBtn = ({
  icon,
  label,
  href = "",
  dropdown,
  active = false,
}: MenuBtn_t) => {
  const [collapse, setCollapse] = useState<boolean>(active ? true : false);
  const { push } = useRouter();
  const { resetFilter } = useFilter();
  const resetScrollPositions = useScrollPositions(
    (state) => state.resetScrollPositions
  );

  return (
    <div>
      <button
        className={cn(
          "flex w-full items-center gap-6  rounded-md px-2 text-left capitalize transition-all duration-300 hover:bg-[#8C56FF]/[0.15] hover:text-white-0",
          active ? "bg-[#8C56FF]/[0.15] text-white-0" : "text-white-500"
        )}
        onClick={() => {
          if (dropdown) {
            setCollapse(!collapse);
          } else {
            resetFilter();
            resetScrollPositions();
            push(href).then(() => {
              resetFilter();
              resetScrollPositions();
            });
          }
        }}
      >
        <div className="relative h-[43px] w-[43px]">
          <Image src={icon} alt={`${label} icon`} fill />
        </div>

        <div className="flex w-full items-center justify-between">
          <p
            className={cn(
              "text-lg capitalize",
              active ? "font-semibold" : "font-bold"
            )}
          >
            {label}
          </p>
          {dropdown && (
            <i
              className={`transition-all duration-300 ${
                collapse && "rotate-90"
              }`}
            >
              <MdKeyboardArrowRight className="text-xl" />
            </i>
          )}
        </div>
      </button>

      <div
        className={`mt-3 overflow-hidden rounded-md bg-[#8C56FF]/[0.15] text-white-500 transition-all duration-500 ${
          collapse ? "max-h-[500px]" : "max-h-0 "
        } flex flex-col`}
      >
        {dropdown &&
          dropdown.map((v, i) => (
            <button
              onClick={() => {
                resetFilter();
                push(v.href ?? "#").then(() => {
                  resetFilter();
                });
              }}
              key={i}
              className={`py-2 pl-[75px] text-left text-lg font-semibold capitalize transition-all duration-500 hover:text-white-0 ${
                v.hidden && "hidden"
              } ${v.active && "text-white-0 "} `}
            >
              {v.label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default MenuBtn;
